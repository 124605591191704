import { useRef, useState } from 'react';
import { PlusIcon } from '@/assets/icons';
import { PlaygroundActionType } from '@/modules/playground/types/playground-action.type';
import { cn } from '@/core/utils/cn';
import usePlayground from '@/modules/playground/hooks/usePlayground.hook';
import Card from '@/core/design-system/Card';
import TwitterText from 'twitter-text';
import { VisibilityWithHeightVariants } from '@/core/constants/mode-variants/visibility.variants';
import ModeRenderization from '../../ModeRenderization';
import { EnterKBD } from '@/core/components/form/fields/EnterKBD';
import toast from 'react-hot-toast';
import { cloudflareWorkerReverseProxy } from '@/core/lib/cloudflare-worker-reverse-proxy';
import { ILinkPreviewResponse } from '@link-in-bio/domain';

const AddLink = () => {
  const [addLink, setAddLink] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { state, dispatch } = usePlayground();

  const toggleAddLink = () => {
    setAddLink((prev) => !prev);
  };

  const dispatchAddLinkHandler = async () => {
    const link = inputRef.current?.value;
    if (link && !TwitterText.isValidUrl(link, true, true)) {
      toast.error('Please enter a valid link');
      return;
    }

    dispatch({
      type: PlaygroundActionType.SET_LINK,
      payload: {
        url: '',
      },
    });

    const linkPreview: ILinkPreviewResponse = await cloudflareWorkerReverseProxy.post(`/link-preview`, { url: link });

    dispatch({
      type: PlaygroundActionType.SET_LINK,
      payload: {
        url: link,
        client_id: crypto.randomUUID(),
        layout: 'default',
        title: linkPreview.title,
        provider_display: linkPreview.provider_display,
        thumbnail_url: linkPreview.images[0],
        favicon_url: linkPreview.favicon,
      },
    });

    toggleAddLink();
  };

  const addLinkKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      dispatchAddLinkHandler();
    }
  };

  const getCardClassname = () => {
    if (addLink || state.payload.links?.length) return 'h-[60px]';
    return '';
  };

  const getCardPlaceholderClassname = () => {
    if (state.payload.links?.length) return 'flex-row';
    return '';
  };

  const getCardPlaceholderTextClassname = () => {
    if (state.payload.links?.length) return 'ml-2 mt-0';
    return '';
  };

  return (
    <ModeRenderization variants={VisibilityWithHeightVariants}>
      <Card className={cn('transition-all duration-200 ease-in-out', getCardClassname())}>
        {addLink ? (
          <div className="relative flex items-center w-full h-full">
            <input
              ref={inputRef}
              type="text"
              className="block w-full h-full rounded-lg border-0 py-[14px] px-2 text-white bg-main focus:outline-none focus:ring-2 focus:input-focus"
              defaultValue="https://"
              autoFocus
              onKeyDown={addLinkKeyDownHandler}
            />
            <EnterKBD onClick={dispatchAddLinkHandler} />
          </div>
        ) : (
          <div
            className={cn('w-full h-full flex flex-col justify-center items-center', getCardPlaceholderClassname())}
            onClick={toggleAddLink}
          >
            <PlusIcon />
            <span className={cn('text-surface font-bold text-sm mt-2', getCardPlaceholderTextClassname())}>
              Add Link
            </span>
          </div>
        )}
      </Card>
    </ModeRenderization>
  );
};

export default AddLink;
