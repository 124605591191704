import { matchRoutes } from 'react-router-dom';

const routes = [
  {
    path: '/auth/register',
  },
  {
    path: '/auth/setup-account',
  },
  {
    path: '/auth/login',
  },
  {
    path: '/auth/confirmation-email-instructions',
  },
  {
    path: '/auth/resend-email-confirmation',
  },
  {
    path: '/auth/forgot-password',
  },
  {
    path: '/auth/reset-password',
  },
  {
    path: '/playground',
  },
  {
    path: '/:username',
  },
];

const paths = routes.map((route) => ({
  path: route.path,
}));

export const isPreviewRoute = () => {
  const route = matchRoutes(paths, window.location.pathname);
  return route?.some((r) => r.route.path === '/:username');
};
