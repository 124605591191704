import { useNavigate, useLocation } from 'react-router-dom';
import { BOARD_URL, SDK_URL } from '../constants/nolt.constants';
import useScript from '../hooks/useScript.hook';
import Cookies from 'js-cookie';

export const useNoltWidget = () => {
  const { isLoaded, error } = useScript(SDK_URL);

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const returnUrl = queryParams.has('returnUrl')!;

  const noltIdentify = () => {
    const { nolt } = window;
    nolt('identify', { jwt: Cookies.get('nolt_token') });

    noltRedirect();
  };

  const noltModal = () => {
    const { nolt } = window;

    nolt('init', { selector: '.nolt-button', url: BOARD_URL });
    nolt('identify', { jwt: Cookies.get('nolt_token') });
  };

  const noltRedirect = () => {
    if (returnUrl) {
      window.location.href = `${BOARD_URL}/sso/${Cookies.get('nolt_token')}?returnUrl=${queryParams.get('returnUrl')}`;
    } else {
      navigate('/playground', {
        replace: true,
      });
    }
  };

  return { isLoaded, error, noltIdentify, noltModal, noltRedirect };
};
