import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

const useAutoResizeTextarea = (fieldName: string) => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const { getValues } = useFormContext();

  useEffect(() => {
    const textarea = textareaRef.current;

    if (textarea) {
      const adjustHeight = () => {
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
      };

      adjustHeight();

      textarea.addEventListener('input', adjustHeight);

      return () => {
        textarea.removeEventListener('input', adjustHeight);
      };
    }
  }, [getValues(fieldName)]);

  return textareaRef;
};

export default useAutoResizeTextarea;
