export type FieldLabelProps = {
  label?: string | null;
};

const FieldLabel = ({ label }: FieldLabelProps) => {
  if (!label) return null;

  return (
    <div aria-label={label} className="text-sm mb-1">
      {label}
    </div>
  );
};

export default FieldLabel;
