import * as React from 'react';
import { type UseFormRegisterReturn } from 'react-hook-form';
import { cn } from '@/core/utils/cn';
import useAutoResizeTextarea from '@/core/hooks/useAutoResizeTextarea.hook';

export type TextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  registration: Partial<UseFormRegisterReturn>;
  fieldName: string;
  className?: string;
  rows?: number;
  maxLength?: number;
};

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, registration, fieldName, rows = 1, maxLength = 250, ...props }, ref) => {
    const textareaRef = useAutoResizeTextarea(fieldName);
    const { ref: formRegisterRef, ...rest } = registration;

    const combinedRef = React.useCallback(
      (node: HTMLTextAreaElement) => {
        textareaRef.current = node;

        if (formRegisterRef) {
          formRegisterRef(node);
        }

        if (ref) {
          (ref as React.MutableRefObject<HTMLTextAreaElement | null>).current = node;
        }
      },
      [formRegisterRef, ref, textareaRef],
    );

    return (
      <textarea
        ref={combinedRef}
        className={cn(
          'text-light text-center bg-transparent border-0 outline-none w-full h-auto rounded-lg px-3 py-2 resize-none overflow-hidden hover:bg-dark hover:text-lighter focus:bg-dark focus:text-lighter transition-all duration-200 ease-in-out disabled:cursor-not-allowed disabled:opacity-50',
          className,
        )}
        rows={rows}
        {...rest}
        {...props}
        maxLength={maxLength}
      />
    );
  },
);

export default Textarea;
