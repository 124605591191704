type SocialIconProps = {
  onClick: () => void;
};

export const FacebookIcon = ({ onClick }: SocialIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
    className="cursor-pointer"
    onClick={onClick}
  >
    <path
      d="M17.1637 39.7791C7.72181 38.116 0.5 29.8892 0.5 20.0008C0.5 9.00004 9.50004 0 20.5008 0C31.5015 0 40.5 9.00004 40.5 20.0008C40.5 29.8892 33.2782 38.1129 23.8332 39.7791L22.7224 38.8939H20.5H18.2776L17.1637 39.7791Z"
      fill="url(#paint0_linear_1662_7073)"
    />
    <path
      d="M28.2779 25.555L29.1662 19.9993H23.8331V16.11C23.8331 14.5542 24.3885 13.3314 26.8326 13.3314H29.3887V8.22073C27.9434 7.99826 26.3892 7.77734 24.9424 7.77734C20.3871 7.77734 17.1636 10.5559 17.1636 15.5561V20.0009H12.1665V25.5565H17.1636V39.6703C19.3635 40.1147 21.6301 40.1147 23.83 39.6703V25.5596L28.2779 25.555Z"
      fill="white"
    />
    <circle cx="20.5" cy="20" r="19.375" stroke="#1B1D21" strokeWidth="1.25" />
    <defs>
      <linearGradient
        id="paint0_linear_1662_7073"
        x1="20.5241"
        y1="38.2433"
        x2="20.5241"
        y2="-0.374719"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0062E0" />
        <stop offset="1" stopColor="#19AFFF" />
      </linearGradient>
    </defs>
  </svg>
);
