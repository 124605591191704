import AuthLayout from '@/core/layouts/auth-layout';
import ResendEmailConfirmationForm from '@/modules/auth/components/ResendEmailConfirmationForm';

const ResendConfirmationEmailRoute = () => {
  return (
    <AuthLayout title="Resend Confirmation Email">
      <ResendEmailConfirmationForm />
    </AuthLayout>
  );
};

export default ResendConfirmationEmailRoute;
