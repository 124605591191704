import { useContext } from 'react';
import { AuthContext } from '../context/auth.context';
import { AuthActionType } from '../types/auth-action.type';
import { StorageConstants } from '@/core/constants/storage.constants';
import useLocalStorage from '@/core/hooks/useLocalStorage';
import useLogout from './useLogout.hook';

const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  const { removeItem } = useLocalStorage(StorageConstants.PLAYGROUND_STORE);

  const logoutApi = useLogout({
    mutationConfig: {
      onSuccess: () => {
        context.dispatch({ type: AuthActionType.LOGOUT_USER });
        window.location.replace('/auth/login');
        removeItem();
      },
    },
  });

  const logout = () => {
    logoutApi.mutate(undefined);
  };

  return {
    ...context,
    logout,
  };
};

export default useAuth;
