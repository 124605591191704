import { ShareIcon } from '@/assets/icons/share.icon';
import { PreivewOnlyVariants } from '@/core/constants/mode-variants/mode.variants';
import ModeRenderization from '@/modules/playground/components/ModeRenderization';

type ShareButtonProps = {
  toggleDialog: () => void;
};

const ShareButton = ({ toggleDialog }: ShareButtonProps) => {
  return (
    <ModeRenderization
      variants={PreivewOnlyVariants}
      className="absolute top-0 right-0 hover:bg-gradient-to-b cursor-pointer bg-surface-dark rounded-full w-10 h-10 py-1 px-[10px] flex justify-center items-center hover:bg-emerald transition-all duration-400 ease-in-out"
      onClick={toggleDialog}
    >
      <ShareIcon />
    </ModeRenderization>
  );
};

export default ShareButton;
