import { useRef } from 'react';

const useImageUpload = () => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const openExplorer = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const uploadImage = (file: File, callback: Function) => {
    const reader = new FileReader();
    reader.onloadend = async () => {
      const base64data = reader.result as string;
      callback(base64data);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return {
    fileInputRef,
    openExplorer,
    uploadImage,
  };
};

export default useImageUpload;
