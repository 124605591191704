export const FaviconDefaultIcon = () => (
  <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M29.4613 17.0032C29.6421 14.7071 29.2071 12.4044 28.2013 10.3325C26.5365 11.9143 24.5685 13.1426 22.416 13.9432C22.5891 16.0676 22.4932 18.2054 22.1307 20.3058C24.75 19.6461 27.2318 18.528 29.4613 17.0032ZM20.012 20.7338C20.4379 18.6978 20.5868 16.6137 20.4547 14.5378C19.1853 14.8405 17.8613 15.0005 16.5 15.0005C15.1387 15.0005 13.8147 14.8405 12.5453 14.5378C12.4163 16.6137 12.5652 18.6974 12.988 20.7338C15.3158 21.0906 17.6843 21.0906 20.012 20.7338ZM13.5293 22.8258C15.5029 23.0593 17.4971 23.0593 19.4707 22.8258C18.7924 25.0093 17.7911 27.0789 16.5 28.9658C15.2089 27.0789 14.2076 25.0093 13.5293 22.8258ZM10.8693 20.3072C10.5049 18.206 10.409 16.0671 10.584 13.9418C8.43105 13.1415 6.46257 11.9132 4.79734 10.3312C3.79176 12.4036 3.35727 14.7068 3.53868 17.0032C5.7682 18.528 8.25004 19.6475 10.8693 20.3072ZM28.9747 19.6698C28.3015 21.9511 27.0163 24.0043 25.2585 25.6067C23.5008 27.2091 21.3377 28.2994 19.004 28.7592C20.1727 26.7942 21.0647 24.6773 21.6547 22.4685C24.2273 21.9293 26.6981 20.9851 28.9747 19.6712V19.6698ZM4.02534 19.6698C6.26801 20.9645 8.73201 21.9205 11.3453 22.4685C11.9354 24.6773 12.8273 26.7942 13.996 28.7592C11.6625 28.2995 9.49953 27.2094 7.7418 25.6073C5.98407 24.0051 4.69874 21.9522 4.02534 19.6712V19.6698ZM19.004 3.24049C22.3076 3.88983 25.2308 5.79493 27.1587 8.55516C25.7356 9.99299 24.0367 11.1286 22.164 11.8938C21.6594 8.83758 20.5877 5.90267 19.004 3.24049ZM16.5 3.03516C18.4459 5.87795 19.7244 9.12364 20.24 12.5298C19.044 12.8365 17.7907 13.0005 16.5 13.0005C15.2093 13.0005 13.956 12.8378 12.76 12.5298C13.2756 9.12363 14.5541 5.87793 16.5 3.03516ZM13.996 3.24049C12.4123 5.90266 11.3406 8.83757 10.836 11.8938C8.96329 11.1286 7.26443 9.99302 5.84134 8.55516C7.76939 5.79533 10.6925 3.88937 13.996 3.24049Z"
      fill="url(#paint0_linear_1779_5696)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1779_5696"
        x1="15.8333"
        y1="2.66536"
        x2="15.8333"
        y2="31.332"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0091F0" />
        <stop offset="1" stopColor="#23262C" />
      </linearGradient>
    </defs>
  </svg>
);
