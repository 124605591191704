import { PlaygroundActionType } from '../types/playground-action.type';
import usePlayground from './usePlayground.hook';
import useImageUpload from '@/core/hooks/useImageUpload.hook';

const useLinkFaviconUpload = () => {
  const { dispatch } = usePlayground();
  const { fileInputRef, openExplorer, uploadImage } = useImageUpload();

  const uploadFaviconHandler = (event: React.ChangeEvent<HTMLInputElement>, linkId: string) => {
    const file = event.target.files?.[0];
    if (!file) return;

    uploadImage(file, (base64data: string) => {
      dispatch({
        type: PlaygroundActionType.SET_LINK_PAYLOAD,
        payload: {
          [`link.${linkId}.favicon_url`]: base64data,
          linkId,
        },
      });
    });
  };

  return {
    fileInputRef,
    openExplorer,
    uploadFaviconHandler,
  };
};

export default useLinkFaviconUpload;
