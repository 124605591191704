import * as React from 'react';
import { FieldErrorsImpl, Merge, type FieldError } from 'react-hook-form';
import FieldLabel from './FieldLabel';
import FieldMessage from './FieldMessage';

type FieldWrapperProps = {
  children: React.ReactNode;
  label?: string;
  className?: string;
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
};

export type FieldWrapperPassThroughProps = Omit<FieldWrapperProps, 'className' | 'children'>;

const FieldWrapper = (props: FieldWrapperProps) => {
  const { label, error, children } = props;
  return (
    <div>
      <FieldLabel label={label} />
      <div>{children}</div>
      <FieldMessage message={error ? (error as FieldError).message : undefined} />
    </div>
  );
};

export default FieldWrapper;
