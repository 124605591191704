import Form from '@/core/components/form/Form';
import { resetPasswordInputSchema } from '../validators/reset-password.validator';
import Input from '@/core/components/form/fields/Input';
import Button from '@/core/design-system/Button';
import useResetPassword from '../hooks/useResetPassword.hook';

type ResetPasswordFormProps = {
  onSuccess: () => void;
  code: string;
};

const ResetPasswordForm = ({ onSuccess, code }: ResetPasswordFormProps) => {
  const resetPassword = useResetPassword({
    mutationConfig: {
      onSuccess,
    },
  });

  return (
    <div>
      <Form
        onSubmit={(values) => {
          resetPassword.mutate({
            ...values,
            code,
          });
        }}
        schema={resetPasswordInputSchema}
      >
        {({ register, formState }) => (
          <>
            <Input
              type="password"
              label="New Password"
              error={formState.errors['new_password']}
              registration={register('new_password')}
            />
            <Input
              type="password"
              label="Confirm Password"
              error={formState.errors['confirm_password']}
              registration={register('confirm_password')}
            />
            <div>
              <Button>Reset Password</Button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};

export default ResetPasswordForm;
