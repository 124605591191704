import { api } from '@/core/lib/api-client';
import { ResponseType, UploadLinkThumbnailDto } from '@link-in-bio/domain';

export const uploadLinkThumbnail = ({
  linkInBioId,
  linkId,
  file,
}: UploadLinkThumbnailDto): Promise<ResponseType<void>> => {
  const formData = new FormData();
  formData.append('file', file);

  return api.post(`/link-in-bio/upload-link-thumbnail/${linkInBioId}?linkId=${linkId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
