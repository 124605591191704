import { useContext, useEffect } from 'react';
import { PlaygroundContext } from '../context/playground.context';
import { PlaygroundAction, PlaygroundActionType } from '../types/playground-action.type';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PlaygroundMode } from '../enums/mode.enum';
import useAuth from '@/modules/auth/hooks/useAuth.hook';
import useModifyLinkInBio from '@/modules/link-in-bio/hooks/useModifyLinkInBio.hook';
import useGetDefaultLinkInBio from '@/modules/link-in-bio/hooks/useGetDefaultLinkInBio.hook';
import useUploadProfileImage from '@/modules/link-in-bio/hooks/useUploadProfileImage';
import { LinkInBioDto, ModifyLinkInBioDto } from '@link-in-bio/domain';
import useUploadLinkThumbnail from '@/modules/link-in-bio/hooks/useUploadLinkThumbnail.hook';
import useUploadLinkFavicon from '@/modules/link-in-bio/hooks/useUploadLinkFavicon.hook';
import { readBase64Image } from '@/core/utils/read-base64-image';
import Cookies from 'js-cookie';
import toast from 'react-hot-toast';
import useGetLinkie from '@/modules/link-in-bio/hooks/useGetLinkie.hook';

const usePlayground = () => {
  const context = useContext(PlaygroundContext);
  if (context === undefined) {
    throw new Error('usePlayground must be used within a PlaygroundProvider');
  }

  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const { state: authState } = useAuth();

  const uploadProfileImage = useUploadProfileImage();
  const uploadLinkThumbnail = useUploadLinkThumbnail();
  const uploadLinkFavicon = useUploadLinkFavicon();

  const modifyLinkInBio = useModifyLinkInBio({
    mutationConfig: {
      onSuccess: async ({ data }: { data: LinkInBioDto }) => {
        const profile_image_url = context.state.payload.profile_image_url;
        const avatar = profile_image_url ? readBase64Image(profile_image_url) : null;

        const mutationPromises = [];

        if (avatar) {
          const uploadProfileImagePromise = uploadProfileImage.mutateAsync({
            linkInBioId: context.state.payload._id!,
            file: avatar,
          });

          mutationPromises.push(uploadProfileImagePromise);
        }

        context.state.payload.links?.forEach((link, index: number) => {
          if (link.thumbnail_url) {
            const thumbnail = link.thumbnail_url ? readBase64Image(link.thumbnail_url) : null;

            if (thumbnail) {
              const linkId = data.links?.[index]?._id;

              const uploadThumbnailPromise = uploadLinkThumbnail.mutateAsync({
                linkInBioId: context.state.payload._id!,
                linkId: linkId ?? '',
                file: thumbnail,
              });

              mutationPromises.push(uploadThumbnailPromise);
            }
          }

          if (link.favicon_url) {
            const favicon = link.favicon_url ? readBase64Image(link.favicon_url) : null;

            if (favicon) {
              const linkId = data.links?.[index]?._id;

              const uploadFaviconPromise = uploadLinkFavicon.mutateAsync({
                linkInBioId: context.state.payload._id!,
                linkId: linkId ?? '',
                file: favicon,
              });

              mutationPromises.push(uploadFaviconPromise);
            }
          }
        });

        try {
          await Promise.all(mutationPromises);
          context.dispatch({ type: PlaygroundActionType.CLEAR_ACTIONS });
          toast.success('Linkie has been updated successfully!');
        } catch (error) {
          console.log(error);
        }
      },
    },
  });

  const defaultLinkInBioQuery = useGetDefaultLinkInBio(authState.user?._id ?? '', {
    enabled: authState.isAuthenticated && !!authState.user?._id,
  });
  const getLinkie = useGetLinkie({
    mutationConfig: {
      onSuccess: async ({ data }: { data: LinkInBioDto }) => {
        context.dispatch({
          type: PlaygroundActionType.SET_INIT_PAYLOAD,
          payload: data as ModifyLinkInBioDto,
        });
      },
    },
  });

  useEffect(() => {
    if (location.pathname !== '/playground') return;
    setDefaultLinkInBioData();
  }, [defaultLinkInBioQuery.data]);

  useEffect(() => {
    if (context.state.payload._id) return;
    setShareablePreviewData();
  }, []);

  const setDefaultLinkInBioData = () => {
    if (!defaultLinkInBioQuery?.data || context.state.payload._id) return;

    context.dispatch({
      type: PlaygroundActionType.SET_INIT_PAYLOAD,
      payload: defaultLinkInBioQuery.data.data as ModifyLinkInBioDto,
    });

    Cookies.set('linkInBioId', defaultLinkInBioQuery.data.data._id);
  };

  const setShareablePreviewData = () => {
    getLinkie.mutate({
      username: params.username,
      domain: window.location.host,
    });
  };

  const handleInputChange =
    (actionType: PlaygroundActionType) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value, name } = e.target;
      const action = {
        type: actionType,
        payload: {
          [name]: value,
        },
      };

      context.dispatchWithDebounce(action as PlaygroundAction);
    };

  const changeMode = () => {
    const mode = context.state.mode === PlaygroundMode.EDITOR ? PlaygroundMode.PREVIEW : PlaygroundMode.EDITOR;
    context.dispatch({ type: PlaygroundActionType.CHANGE_MODE, payload: mode });
  };

  const openPreview = () => {
    if (!authState.isAuthenticated) {
      navigate('/auth/register');
      return;
    }

    window.open(`${process.env.HOME_URL}/${context.state.payload.username}`, '_blank');
  };

  const handleSubmit = async () => {
    if (authState.isAuthenticated) {
      modifyLinkInBio.mutate({
        ...context.state.payload,
        profile_image_url: undefined,
        links: context.state.payload.links?.map((link) => ({
          ...link,
          thumbnail_url: link.thumbnail_url && link.thumbnail_url.startsWith('data:') ? undefined : link.thumbnail_url,
          favicon_url: link.favicon_url && link.favicon_url.startsWith('data:') ? undefined : link.favicon_url,
        })),
      });

      return;
    }

    navigate('/auth/register');
  };

  return {
    ...context,
    handleInputChange,
    handleSubmit,
    changeMode,
    openPreview,
  };
};

export default usePlayground;
