export const ThumbnailDefaultIcon = () => (
  <svg
    width="184"
    height="150"
    viewBox="0 0 184 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="rounded-[8px]"
  >
    <rect x="0.5" width="188" height="150" rx="8" fill="#1B1D21" />
    <g clipPath="url(#clip0_1779_5644)">
      <g clipPath="url(#clip1_1779_5644)">
        <path
          d="M92.7183 64.7134C93.0588 65.0035 93.2984 65.3881 93.5822 65.7286C94.0425 66.2772 94.4901 66.8447 94.9378 67.4058C95.0072 67.4941 95.1018 67.5761 95.1081 67.7022C94.9063 67.6707 94.7487 67.7337 94.6099 67.8913C93.6894 68.9507 92.7751 70.01 91.8419 71.0566C91.5392 71.4034 91.2681 71.7755 90.9087 72.0781C90.8456 71.9898 90.7826 71.9016 90.7069 71.8196C90.0701 71.0882 89.408 70.3694 88.7901 69.619C88.0082 68.648 86.8985 68.7615 86.2679 69.5244C85.9779 69.8838 85.6626 70.2243 85.36 70.5711C84.5087 71.5485 83.6512 72.5258 82.8 73.4968C82.8 69.8145 82.8 66.1385 82.8 62.4561C82.8504 62.4246 82.8504 62.3931 82.8 62.3615C82.8 62.33 82.8 62.2985 82.8 62.267C82.9765 62.1282 82.9765 61.9012 83.0648 61.7184C83.4936 60.8482 84.1746 60.3249 85.1204 60.142C85.3789 60.0916 85.6374 60.0979 85.8959 60.0979C88.9856 60.0979 92.0752 60.0979 95.1648 60.0979C95.3603 60.0979 95.5494 60.0979 95.644 60.306C95.7449 60.5204 95.6377 60.6717 95.499 60.8293C94.8117 61.6175 94.1244 62.4183 93.4435 63.2128C93.2039 63.4902 92.9706 63.7739 92.7309 64.0514C92.7057 64.0766 92.6679 64.0892 92.6364 64.1144C92.4031 63.2065 91.3942 62.6138 90.4799 62.8092C89.6476 62.9858 88.9099 63.8244 89.0297 64.8459C89.1495 65.8799 90.1268 66.6744 91.2492 66.4348C92.1004 66.2583 92.6364 65.6088 92.7183 64.7134Z"
          fill="#23262C"
        />
        <path
          d="M96.9934 67.9805C96.6718 67.7093 96.4511 67.3562 96.1926 67.041C95.6693 66.4167 95.1648 65.7799 94.6604 65.1431C94.2127 64.5756 94.2506 64.027 94.7235 63.4847C95.5999 62.4822 96.4637 61.4733 97.3276 60.4582C97.5293 60.2186 97.7437 60.0798 98.0968 60.0861C100.077 60.1114 102.057 60.1051 104.036 60.0924C104.831 60.0861 105.518 60.3321 106.092 60.868C106.533 61.2779 106.798 61.8012 106.912 62.3939C106.924 62.4633 106.905 62.5452 107.006 62.5515C107.006 62.5957 107.006 62.6461 107.006 62.6903C106.918 62.7722 106.962 62.8857 106.962 62.9803C106.962 66.3663 106.962 69.746 106.962 73.132C106.962 73.2391 106.956 73.3526 106.95 73.4598C106.275 72.6969 105.594 71.9339 104.919 71.1647C103.526 69.5757 102.139 67.9805 100.739 66.4041C100.033 65.6096 99.0363 65.6349 98.3427 66.4357C97.8887 66.9527 97.4411 67.4634 96.9934 67.9805Z"
          fill="#23262C"
        />
        <path
          d="M82.8 73.4965C83.6512 72.5254 84.5087 71.5481 85.36 70.5771C85.6626 70.2303 85.9779 69.8835 86.2679 69.5304C86.8922 68.7611 88.0019 68.6476 88.7964 69.6187C89.4143 70.369 90.0764 71.0878 90.7132 71.8192C90.7826 71.9012 90.8456 71.9958 90.915 72.0777C90.8772 72.1408 90.8519 72.2039 90.8078 72.2543C90.1205 73.0362 89.4395 73.8243 88.746 74.5999C88.2352 75.1674 88.3739 76.006 89.0423 76.378C89.0927 76.4095 89.1621 76.4221 89.1747 76.4978C87.9578 76.5041 86.7408 76.5041 85.5302 76.5104C85.1645 76.5104 84.8114 76.4285 84.4835 76.2771C83.6575 75.8988 83.1216 75.2746 82.8883 74.3855C82.8693 74.3098 82.8883 74.209 82.8063 74.1522C82.8 73.9378 82.8 73.7171 82.8 73.4965Z"
          fill="#425465"
        />
        <path
          d="M96.9934 67.9824C97.4411 67.4653 97.8951 66.9546 98.3427 66.4376C99.0363 65.6431 100.033 65.6179 100.739 66.406C102.139 67.9887 103.526 69.5776 104.919 71.1666C105.594 71.9359 106.269 72.6988 106.95 73.4618C107.076 75.1453 105.815 76.5136 104.125 76.5136C102.019 76.5136 99.9128 76.5136 97.8005 76.5199C97.6491 76.5199 97.5609 76.4757 97.4663 76.3622C96.5394 75.2525 95.6125 74.1427 94.6793 73.0393C94.219 72.497 94.2127 71.9674 94.6793 71.4314C95.4233 70.5676 96.1674 69.7038 96.9114 68.8399C97.2519 68.4364 97.2519 68.4301 96.9934 67.9824Z"
          fill="#3A4253"
        />
        <path
          d="M89.1747 76.507C89.1684 76.4313 89.0991 76.4124 89.0423 76.3872C88.3739 76.0152 88.2352 75.1766 88.746 74.6091C89.4395 73.8335 90.1205 73.0454 90.8078 72.2635C90.852 72.213 90.8835 72.1437 90.915 72.0869C91.2681 71.7906 91.5392 71.4123 91.8482 71.0655C92.7751 70.0188 93.6957 68.9595 94.6163 67.9002C94.755 67.7425 94.9126 67.6795 95.1144 67.711C95.4864 68.02 95.5305 68.6379 95.1711 69.0919C94.8559 69.4954 94.5154 69.8737 94.1812 70.2584C93.7209 70.7943 93.2606 71.3303 92.8003 71.8662C92.5292 72.1815 92.5292 72.295 92.8003 72.6166C93.6894 73.6759 94.5784 74.7352 95.4675 75.7882C95.5936 75.9395 95.7008 76.0908 95.6125 76.2989C95.5179 76.5196 95.3225 76.5259 95.1144 76.5259C93.2858 76.5259 91.4573 76.5259 89.6224 76.5259C89.4711 76.5196 89.3197 76.507 89.1747 76.507Z"
          fill="#3A4253"
        />
        <path
          d="M92.7183 64.7126C92.6364 65.6079 92.1004 66.2574 91.2429 66.4402C90.1205 66.6798 89.1432 65.8854 89.0234 64.8513C88.9036 63.8235 89.6413 62.9912 90.4736 62.8147C91.3879 62.6192 92.3968 63.2119 92.6301 64.1199C92.5796 64.3279 92.6174 64.5234 92.7183 64.7126Z"
          fill="#425465"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1779_5644">
        <rect width="24" height="24" fill="white" transform="translate(83 56)" />
      </clipPath>
      <clipPath id="clip1_1779_5644">
        <rect width="24.2" height="16.4318" fill="white" transform="translate(82.8 60.0859)" />
      </clipPath>
    </defs>
  </svg>
);
