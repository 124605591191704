import { LinkDto } from '@link-in-bio/domain';
import { CameraIcon } from '@/assets/icons';
import useLinkFaviconUpload from '@/modules/playground/hooks/useLinkFaviconUpload.hook';
import { FaviconDefaultIcon } from '@/assets/icons/favicon-default.icon';

type LinkPreviewFaviconProps = {
  link: LinkDto;
};

const LinkPreviewFavicon = ({ link }: LinkPreviewFaviconProps) => {
  const {
    fileInputRef: faviconFileInputRef,
    openExplorer: faviconOpenExplorer,
    uploadFaviconHandler: faviconUploadFaviconHandler,
  } = useLinkFaviconUpload();

  const getFaviconSrc = () => {
    if (link.favicon_url) return link.favicon_url;
    return undefined;
  };

  return (
    <>
      <div className="relative group/thumbnail cursor-pointer w-8 h-8" onClick={faviconOpenExplorer}>
        {getFaviconSrc() ? (
          <img src={getFaviconSrc()} alt="Link Icon" className="rounded-lg w-full h-full object-contain" />
        ) : (
          <FaviconDefaultIcon />
        )}
        <div className="absolute inset-0 bg-black opacity-0 group-hover/thumbnail:opacity-40 rounded-lg transition-all duration-200"></div>
        <div className="absolute inset-0 flex items-center justify-center text-white opacity-0 group-hover/thumbnail:opacity-100 transition-all duration-200">
          <CameraIcon size={4} />
        </div>
      </div>

      <input
        ref={faviconFileInputRef}
        type="file"
        accept=".jpg,.jpeg,.png,.webp"
        className="hidden"
        id="favicon"
        name="favicon"
        onChange={(e) => faviconUploadFaviconHandler(e, link._id || link.client_id || '')}
      />
    </>
  );
};

export default LinkPreviewFavicon;
