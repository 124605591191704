import { useMutation } from '@tanstack/react-query';
import { MutationConfig } from '@/core/lib/query-client';
import { verifyTwoFactorCode } from '../api/verify-two-factor-code.api';

type UseVerifyTwoFactorCodeOptions = {
  mutationConfig?: MutationConfig<typeof verifyTwoFactorCode>;
};

const useVerifyTwoFactorCode = ({ mutationConfig }: UseVerifyTwoFactorCodeOptions = {}) => {
  const { onSuccess, onError, ...restConfig } = mutationConfig || {};

  return useMutation({
    onSuccess,
    onError,
    ...restConfig,
    mutationFn: verifyTwoFactorCode,
  });
};

export default useVerifyTwoFactorCode;
