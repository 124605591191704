import PlaygroundLayout from '@/core/layouts/playground-layout';
import useAuth from '@/modules/auth/hooks/useAuth.hook';
import BrowserFrame from '@/modules/playground/components/BrowserFrame';
import PlaygroundForm from '@/modules/playground/components/playground-form';
import { PlaygroundMode } from '@/modules/playground/enums/mode.enum';
import usePlayground from '@/modules/playground/hooks/usePlayground.hook';
import { initialState } from '@/modules/playground/store/playground-reducer';
import { PlaygroundActionType } from '@/modules/playground/types/playground-action.type';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const PlaygroundRoute = () => {
  const { dispatch } = usePlayground();
  const { state: authState } = useAuth();
  const [searchParams] = useSearchParams();
  const username = searchParams.get('username');

  useEffect(() => {
    setVisitorData();
  }, []);

  const setVisitorData = () => {
    if (authState.isAuthenticated) {
      dispatch({
        type: PlaygroundActionType.CHANGE_MODE,
        payload: PlaygroundMode.EDITOR,
      });

      return;
    }

    if (username) {
      dispatch({
        type: PlaygroundActionType.CHANGE_MODE,
        payload: PlaygroundMode.EDITOR,
      });

      dispatch({
        type: PlaygroundActionType.SET_INIT_PAYLOAD,
        payload: {
          ...initialState.payload,
          username,
        },
      });

      return;
    }

    window.location.href = process.env.HOME_URL!;
  };

  return (
    <PlaygroundLayout>
      <BrowserFrame>
        <PlaygroundForm />
      </BrowserFrame>
    </PlaygroundLayout>
  );
};

export default PlaygroundRoute;
