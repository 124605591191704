export const readBase64Image = (base64String: string, name: string = 'profile_image.png') => {
  if (!base64String?.startsWith('data:image')) return null;

  const byteString = atob(base64String.split(',')[1]);
  const mimeString = base64String.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  const file = new File([ab], name, { type: mimeString });
  return file;
};
