import { api } from '@/core/lib/api-client';
import { ResponseType, UploadLinkFaviconDto } from '@link-in-bio/domain';

export const uploadLinkFavicon = ({ linkInBioId, linkId, file }: UploadLinkFaviconDto): Promise<ResponseType<void>> => {
  const formData = new FormData();
  formData.append('file', file);

  return api.post(`/link-in-bio/upload-link-favicon/${linkInBioId}?linkId=${linkId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
