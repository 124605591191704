import { cn } from '../utils/cn';

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  children: React.ReactNode;
  type?: 'submit' | 'button';
  variant?: 'filled' | 'outlined';
  color?: 'primary' | 'secondary';
  className?: string;
  onClick?: () => void;
};

const baseClasses =
  'flex w-full justify-center rounded-lg px-3 py-2 text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:bg-secondary transition-all duration-200 ease-in-out';

const filledClasses = {
  primary: 'bg-primary text-white shadow-sm hover:bg-primary-dark',
  secondary: 'bg-secondary text-white shadow-sm hover:bg-dark',
};

const outlinedClasses = {
  primary: 'border border-primary text-primary bg-transparent hover:bg-primary hover:text-white',
  secondary: 'border border-secondary bg-transparent hover:bg-secondary text-light',
};

const Button = ({
  children,
  type = 'submit',
  variant = 'filled',
  color = 'primary',
  className,
  onClick,
  ...props
}: ButtonProps) => {
  const variantClasses = variant === 'filled' ? filledClasses[color] : outlinedClasses[color];

  return (
    <button type={type} className={cn(baseClasses, variantClasses, className)} onClick={onClick} {...props}>
      {children}
    </button>
  );
};

export default Button;
