import { useFormContext } from 'react-hook-form';
import { PlaygroundActionType } from '@/modules/playground/types/playground-action.type';
import usePlayground from '@/modules/playground/hooks/usePlayground.hook';
import { PlaygroundMode } from '@/modules/playground/enums/mode.enum';
import Input from '@/core/components/form/fields/Input';
import Textarea from '@/core/components/form/fields/Textarea';
import ProfileCTAButton from './ProfileCTAButton';
import Markdown from '@/core/components/markdown';
import { motion } from 'framer-motion';
import { ProfileInputVariants } from '@/core/constants/mode-variants/input.variants';
import { cn } from '@/core/utils/cn';

const MotionInput = motion.create(Input);

const Details = () => {
  const { register } = useFormContext();
  const { state, handleInputChange } = usePlayground();

  const getNameContainerClassname = () => {
    if (state.mode === PlaygroundMode.PREVIEW && !state.payload.name) return 'invisible opacity-0';
  };

  const getTitleContainerClassname = () => {
    if (state.mode === PlaygroundMode.PREVIEW && !state.payload.title) return 'invisible opacity-0';
  };

  return (
    <div className="text-center mt-4 mb-11 w-full">
      <div className={cn('font-bold text-2xl', getNameContainerClassname())}>
        <MotionInput
          key={state.mode}
          initial={state.mode === PlaygroundMode.EDITOR ? 'Preview' : 'Editor'}
          animate={state.mode === PlaygroundMode.EDITOR ? 'Editor' : 'Preview'}
          variants={ProfileInputVariants}
          type="name"
          registration={register('name')}
          placeholder="Name"
          onChange={handleInputChange(PlaygroundActionType.SET_PAYLOAD)}
          className="text-light text-center border-0 outline-none w-full truncate hover:bg-dark hover:text-lighter focus:bg-dark focus:text-lighter"
          transition={{
            duration: 0.4,
            ease: 'easeInOut',
          }}
        />
      </div>
      <div className={cn('font-bold text-xs mt-1 mb-4', getTitleContainerClassname())}>
        <MotionInput
          key={state.mode}
          initial={state.mode === PlaygroundMode.EDITOR ? 'Preview' : 'Editor'}
          animate={state.mode === PlaygroundMode.EDITOR ? 'Editor' : 'Preview'}
          variants={ProfileInputVariants}
          type="title"
          registration={register('title')}
          placeholder="Title"
          onChange={handleInputChange(PlaygroundActionType.SET_PAYLOAD)}
          className="text-light text-center border-0 outline-none w-full truncate hover:bg-dark hover:text-lighter focus:bg-dark focus:text-lighter"
          transition={{
            duration: 0.4,
            ease: 'easeInOut',
          }}
        />
      </div>
      <div className="text-sm w-full sm:w-[70%] m-auto">
        {state.mode === PlaygroundMode.EDITOR ? (
          <Textarea
            registration={register('bio')}
            fieldName="bio"
            placeholder="Bio"
            onChange={handleInputChange(PlaygroundActionType.SET_PAYLOAD)}
            className="transition-all duration-200 ease-in-out mt-2"
          />
        ) : (
          <Markdown text={state.payload.bio!} />
        )}
      </div>

      <ProfileCTAButton />
    </div>
  );
};

export default Details;
