import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';
import usePlayground from '../../../playground/hooks/usePlayground.hook';
import { removeHttpProtocol } from '@/core/utils/remove-http-protocol';
import Button from '@/core/design-system/Button';
import { useState } from 'react';
import DefaultAvatarAsset from '@/assets/images/default-avatar.png';
import { CloseIcon } from '@/assets/icons/close.icon';
import { FacebookIcon } from '@/assets/icons/socials/facebook.icon';
import { WhatsappIcon } from '@/assets/icons/socials/whatsapp.icon';
import { TwitterIcon } from '@/assets/icons/socials/twitter.icon';
import { LinkedInIcon } from '@/assets/icons/socials/linkedin.icon';
import { ThreadsIcon } from '@/assets/icons/socials/threads.icon';
import { BlueskyIcon } from '@/assets/icons/socials/bluesky.icon';
import { MastodonIcon } from '@/assets/icons/socials/mastodon.icon';

type ShareDialogProps = {
  isOpen: boolean;
  toggleDialog: () => void;
};

const ShareDialog = ({ isOpen, toggleDialog }: ShareDialogProps) => {
  const { state } = usePlayground();
  const [copyButtonText, setCopyButtonText] = useState<string>('Copy');

  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(`${process.env.HOME_URL}/${state.payload.username}`)
      .then(() => {
        setCopyButtonText('Copied');

        setTimeout(() => {
          setCopyButtonText('Copy');
        }, 2000);
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  };

  const shareLink = (platform: string) => {
    let shareUrl = '';
    const url = `${process.env.HOME_URL}/${state.payload.username}`;

    switch (platform) {
      case 'facebook':
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
        break;
      case 'whatsapp':
        shareUrl = `https://wa.me/?text=${encodeURIComponent(url)}`;
        break;
      case 'twitter':
        shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}`;
        break;
      case 'linkedin':
        shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`;
        break;
      case 'threads':
        shareUrl = `https://www.threads.net/intent/post?text=${encodeURIComponent(url)}`;
        break;
      case 'bluesky':
        shareUrl = `https://bsky.app/intent/compose?url=${encodeURIComponent(url)}`;
        break;
      case 'mastodon':
        shareUrl = `https://mastodonshare.com?url=${encodeURIComponent(url)}`;
        break;
      default:
        shareUrl = url;
    }

    return shareUrl;
  };

  return (
    <Dialog open={isOpen} onClose={toggleDialog} className="relative z-50">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-[rgba(0,0,0,0.40)] backdrop-[blur(2px)] duration-200 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
      />

      <div className="fixed inset-0 flex flex-col w-screen items-center justify-center">
        <DialogPanel
          transition
          className="relative w-[410px] space-y-4 bg-transparent duration-200 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
        >
          <div className="p-6 text-center rounded-2xl bg-radial-gradient shadow-radial-shadow">
            <CloseIcon toggleDialog={toggleDialog} />

            <div className="relative">
              <img
                src={state.payload.profile_image_url ?? DefaultAvatarAsset}
                alt="Profile Image"
                className="mx-auto w-[140px] h-[140px] rounded-full shadow-profile-image-shadow"
              />

              <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 trans w-fit text-xs rounded-3xl flex justify-center items-center py-1 px-2 text-lighter bg-profile-gradient shadow-profile-shadow">
                {state.payload.name}
              </div>
            </div>

            <div className="py-8">
              <h1 className="text-lighter text-2xl font-bold mb-2">Share your Linkie</h1>
            </div>

            <div className="relative bg-dark border border-solid border-secondary py-2 px-2 rounded-xl">
              <input
                value={removeHttpProtocol(`${process.env.HOME_URL}/${state.payload.username}`)}
                className="text-sm outline-none w-full rounded-lg border border-solid border-secondary bg-main py-2 px-4 text-lighter"
                readOnly
              />
              <div
                className="absolute top-1/2 right-[10px] transform -translate-y-1/2 flex py-[2px] pr-[2px] h-8 cursor-pointer"
                onClick={handleCopyClick}
              >
                <kbd className="inline-flex items-center rounded-[5px] py-2 px-3 text-sm text-lighter font-bold bg-primary">
                  {copyButtonText}
                </kbd>
              </div>
            </div>

            <div className="pt-8">
              <p className="text-light text-sm text-left mb-2">Share on socials</p>

              <div className="rounded-xl p-3 bg-main flex justify-center items-center gap-4">
                <FacebookIcon onClick={() => window.open(shareLink('facebook'), '_blank')} />
                <WhatsappIcon onClick={() => window.open(shareLink('whatsapp'), '_blank')} />
                <TwitterIcon onClick={() => window.open(shareLink('twitter'), '_blank')} />
                <LinkedInIcon onClick={() => window.open(shareLink('linkedin'), '_blank')} />
                <ThreadsIcon onClick={() => window.open(shareLink('threads'), '_blank')} />
                <BlueskyIcon onClick={() => window.open(shareLink('bluesky'), '_blank')} />
                <MastodonIcon onClick={() => window.open(shareLink('mastodon'), '_blank')} />
              </div>
            </div>
          </div>

          <div className="relative space-y-4 rounded-2xl p-6 mt-2 bg-dark border border-solid border-secondary shadow-surface-shadow">
            <h3 className="font-[600] text-lg text-lighter">Create your Linkie by joining now</h3>
            <p className="text-light text-sm pb-3">
              Get started and put all your links and posts across multiple social network in one place
            </p>

            <div className="flex gap-2">
              <Button
                type="button"
                className="w-fit"
                onClick={() => {
                  window.open(process.env.HOME_URL, '_blank', 'noreferrer');
                }}
              >
                Create Linkie
              </Button>
              <Button
                type="button"
                variant="outlined"
                color="secondary"
                className="w-fit"
                onClick={() => {
                  window.open(process.env.HOME_URL, '_blank', 'noreferrer');
                }}
              >
                Learn More
              </Button>
            </div>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  );
};

export default ShareDialog;
