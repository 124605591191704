import { removeHttpProtocol } from '@/core/utils/remove-http-protocol';
import ReactMarkdown from 'react-markdown';
import TwitterText from 'twitter-text';

type MarkdownProps = {
  text: string;
};

const Markdown = ({ text }: MarkdownProps) => {
  const transformTextWithLinks = (text: string) => {
    const urls = TwitterText.extractUrls(text);

    if (!urls.length) return text;

    let transformedText = text;

    urls.forEach((url) => {
      const markdownRegex = new RegExp(`\\[.*?\\]\\(${url}\\)`);
      if (!markdownRegex.test(transformedText)) {
        transformedText = transformedText.replace(url, `[${removeHttpProtocol(url)}](${url})`);
      }
    });

    return transformedText;
  };

  return (
    <ReactMarkdown
      className="whitespace-pre-wrap pointer-events-auto break-words mt-2 text-lighter text-center bg-transparent border-0 outline-none w-full h-auto rounded-lg px-3"
      components={{
        a: (props: Record<string, any>) => (
          <a
            href={props.href.startsWith('https://') ? props.href : `https://${props.href}`}
            target="_blank"
            className="text-primary"
          >
            {props.children}
          </a>
        ),
        h1: () => null,
        h2: () => null,
        h3: () => null,
        h4: () => null,
        h5: () => null,
        h6: () => null,
        blockquote: () => null,
        code: () => null,
        ul: () => null,
        ol: () => null,
        hr: () => null,
        img: () => null,
      }}
    >
      {transformTextWithLinks(text)}
    </ReactMarkdown>
  );
};

export default Markdown;
