import { useMutation } from '@tanstack/react-query';
import { MutationConfig } from '@/core/lib/query-client';
import { uploadLinkThumbnail } from '../api/upload-link-thumbnail.api';

type UseUploadLinkThumbnailOptions = {
  mutationConfig?: MutationConfig<typeof uploadLinkThumbnail>;
};

const useUploadLinkThumbnail = ({ mutationConfig }: UseUploadLinkThumbnailOptions = {}) => {
  const { onSuccess, ...restConfig } = mutationConfig || {};

  return useMutation({
    onSuccess,
    ...restConfig,
    mutationFn: uploadLinkThumbnail,
  });
};

export default useUploadLinkThumbnail;
