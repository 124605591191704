import { LinkDto, ModifyLinkDto } from '@link-in-bio/domain';
import { Reorder } from 'framer-motion';
import { PlaygroundActionType } from '@/modules/playground/types/playground-action.type';
import { PlaygroundMode } from '@/modules/playground/enums/mode.enum';
import usePlayground from '@/modules/playground/hooks/usePlayground.hook';
import AddLink from './AddLink';
import LinkPreview from './link-preview';
import LinkPreviewProvider from '@/modules/playground/context/link-preview.context';
import { cn } from '@/core/utils/cn';
import { ENUM_LINK_LAYOUT } from '@/modules/playground/enums/link.enum';

const Links = () => {
  const { state, dispatchWithDebounce } = usePlayground();

  const onReorderHandler = (newOrder: ModifyLinkDto[]) => {
    dispatchWithDebounce({
      type: PlaygroundActionType.REORDER_LINKS,
      payload: newOrder,
    });
  };

  return (
    <div className="w-full text-center">
      <AddLink />
      <Reorder.Group values={state.payload.links || []} onReorder={onReorderHandler}>
        <div
          className={cn(
            'gap-y-3',
            state.mode === PlaygroundMode.EDITOR ? 'grid grid-cols-6' : 'w-full flex flex-wrap justify-center',
          )}
        >
          {state.payload.links?.map((link: LinkDto | any) => {
            const layoutType = link.layout;
            const key = `${link._id || link.client_id}-${layoutType}`;
            const isEditorMode = state.mode === PlaygroundMode.EDITOR;

            let gridClass = 'col-span-6 flex-grow flex-shrink basis-full';

            switch (layoutType) {
              case ENUM_LINK_LAYOUT.ICON:
                gridClass = 'col-span-1 px-1';
                break;
              case ENUM_LINK_LAYOUT.LINE:
                gridClass = 'col-span-6 flex-grow flex-shrink basis-full px-1';
                break;
              case ENUM_LINK_LAYOUT.SMALL:
                gridClass = 'col-span-3 basis-1/2 px-1';
                break;
              default:
                gridClass = 'col-span-6 flex-grow flex-shrink basis-full px-1';
                break;
            }

            return (
              <Reorder.Item key={key} value={link} drag={isEditorMode && state.isLinkDraggable} className={gridClass}>
                <div className="w-full">
                  <LinkPreviewProvider link={link}>
                    <LinkPreview link={link} />
                  </LinkPreviewProvider>
                </div>
              </Reorder.Item>
            );
          })}
        </div>
      </Reorder.Group>
    </div>
  );
};

export default Links;
