import { LinkDto } from '@link-in-bio/domain';
import { PlaygroundActionType } from '@/modules/playground/types/playground-action.type';
import { useFieldArray, useFormContext } from 'react-hook-form';
import usePlayground from '@/modules/playground/hooks/usePlayground.hook';
import Card from '@/core/design-system/Card';
import LayoutButtons from './LayoutButtons';
import DefaultLayout from './layouts/default-layout';
import SmallLayout from './layouts/small-layout';
import LineLayout from './layouts/line-layout';
import IconLayout from './layouts/icon-layout';
import { PlaygroundMode } from '@/modules/playground/enums/mode.enum';
import { LayoutHeights, LayoutWidths } from '@/core/constants/link-preview.constants';
import { PointerEventVariants } from '@/core/constants/mode-variants/event.variants';
import { TrashIcon } from '@/assets/icons/trash.icon';
import { motion } from 'framer-motion';
import ModeRenderization from '../../../ModeRenderization';
import { ENUM_LINK_LAYOUT } from '@/modules/playground/enums/link.enum';
import DefaultSkeleton from './skeleton/default-skeleton';
import IconSkeleton from './skeleton/icon-skeleton';
import LineSkeleton from './skeleton/line-skeleton';
import SmallSkeleton from './skeleton/small-skeleton';

type LinkPreviewProps = {
  link: LinkDto;
};

const LinkPreview = ({ link }: LinkPreviewProps) => {
  const { state, dispatch } = usePlayground();
  const { control } = useFormContext();

  // @ts-ignore
  const { fields } = useFieldArray({
    control,
    name: 'links',
  });

  const deleteLinkHandler = () => {
    dispatch({
      type: PlaygroundActionType.DELETE_LINK,
      payload: link._id || link.client_id || '',
    });
  };

  const renderLayout = () => {
    switch (link.layout) {
      case ENUM_LINK_LAYOUT.ICON:
        return <IconLayout />;
      case ENUM_LINK_LAYOUT.LINE:
        return <LineLayout />;
      case ENUM_LINK_LAYOUT.SMALL:
        return <SmallLayout />;
      default:
        return <DefaultLayout />;
    }
  };

  const renderSkeleton = () => {
    switch (link.layout) {
      case ENUM_LINK_LAYOUT.ICON:
        return <IconSkeleton />;
      case ENUM_LINK_LAYOUT.LINE:
        return <LineSkeleton />;
      case ENUM_LINK_LAYOUT.SMALL:
        return <SmallSkeleton />;
      default:
        return <DefaultSkeleton />;
    }
  };

  return (
    <div
      className="cursor-pointer"
      onClick={() => {
        if (link.url && state.mode === PlaygroundMode.PREVIEW) {
          window.open(link.url, '_blank');
        }
      }}
    >
      <ModeRenderization variants={PointerEventVariants} className="group/card">
        <Card
          className={`relative bg-dark text-lighter ${LayoutWidths[link.layout]} ${LayoutHeights[link.layout]}`}
          key={link._id || link.client_id}
        >
          {!link.url && renderSkeleton()}
          {link.url && (
            <motion.div
              className="w-full"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.4 }}
            >
              {renderLayout()}
            </motion.div>
          )}

          <LayoutButtons linkId={link._id || link.client_id || ''} />

          <button
            type="button"
            className="bg-surface-gradient shadow-surface-shadow absolute -top-3.5 -right-3.5 rounded-full p-2 text-white opacity-0 invisible pointer-events-none group-hover/card:opacity-100 group-hover/card:visible group-hover/card:pointer-events-auto transition-all duration-200 ease-in-out"
            onClick={deleteLinkHandler}
          >
            <TrashIcon className="h-4 w-4" />
          </button>
        </Card>
      </ModeRenderization>
    </div>
  );
};

export default LinkPreview;
