import { resendEmailConfirmationInputSchema } from '../validators/resend-email-confirmation.validator';
import ReCAPTCHA from 'react-google-recaptcha';
import { ForgotPasswordDto } from '@link-in-bio/domain';
import { useRef } from 'react';
import Form from '@/core/components/form/Form';
import Input from '@/core/components/form/fields/Input';
import Button from '@/core/design-system/Button';
import Recaptcha from '@/core/components/recaptcha';
import useForgotPassword from '../hooks/useForgotPassword.hook';

type ForgotPasswordFormProps = {
  onSuccess: () => void;
};

const ForgotPasswordForm = ({ onSuccess }: ForgotPasswordFormProps) => {
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);

  const forgotPassword = useForgotPassword({
    mutationConfig: {
      onSuccess,
    },
  });

  const onSubmitHandler = async (values: ForgotPasswordDto) => {
    const captcha_token = await recaptchaRef.current?.executeAsync();
    forgotPassword.mutate({ ...values, captcha_token: captcha_token ?? '' });
    recaptchaRef.current?.reset();
  };

  return (
    <div>
      <Form onSubmit={onSubmitHandler} schema={resendEmailConfirmationInputSchema}>
        {({ register, formState }) => (
          <>
            <Input
              type="email"
              label="Email Address"
              error={formState.errors['email']}
              registration={register('email')}
            />

            <div>
              <Button>Reset Password</Button>
            </div>

            <Recaptcha ref={recaptchaRef} />
          </>
        )}
      </Form>
    </div>
  );
};

export default ForgotPasswordForm;
