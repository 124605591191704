import { useMutation } from '@tanstack/react-query';
import { MutationConfig } from '@/core/lib/query-client';
import { register } from '../api/register.api';

type UseRegisterOptions = {
  mutationConfig?: MutationConfig<typeof register>;
};

const useRegister = ({ mutationConfig }: UseRegisterOptions = {}) => {
  const { onSuccess, ...restConfig } = mutationConfig || {};

  return useMutation({
    onSuccess,
    ...restConfig,
    mutationFn: register,
  });
};

export default useRegister;
