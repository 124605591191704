import { z } from 'zod';

export const loginInputSchema = z.object({
  email: z.string().min(1, { message: 'Email is required!' }).email('This is not a valid email!'),
  password: z
    .string()
    .min(1, { message: 'Password is required!' })
    .min(8, { message: 'Passowrd should be at least 8 characters long!' }),
  remember_me: z.boolean(),
});
