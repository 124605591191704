import { useMutation } from '@tanstack/react-query';
import { MutationConfig } from '@/core/lib/query-client';
import { uploadLinkFavicon } from '../api/upload-link-favicon.api';

type UseUploadLinkFaviconOptions = {
  mutationConfig?: MutationConfig<typeof uploadLinkFavicon>;
};

const useUploadLinkFavicon = ({ mutationConfig }: UseUploadLinkFaviconOptions = {}) => {
  const { onSuccess, ...restConfig } = mutationConfig || {};

  return useMutation({
    onSuccess,
    ...restConfig,
    mutationFn: uploadLinkFavicon,
  });
};

export default useUploadLinkFavicon;
