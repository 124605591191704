import { Link as RouterLink, LinkProps } from 'react-router-dom';
import { cn } from '@/core/utils/cn';

const Link = ({ className, children, ...props }: LinkProps) => {
  return (
    <RouterLink className={cn('text-slate-600 hover:text-slate-900', className)} {...props}>
      {children}
    </RouterLink>
  );
};

export default Link;
