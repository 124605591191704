import { setupAccountInputSchema } from '../validators/setup-account.validator';
import { UserDto } from '@link-in-bio/domain';
import { SetupAccountRouteParams } from '@/app/router/auth/SetupAccount.route';
import Form from '@/core/components/form/Form';
import Input from '@/core/components/form/fields/Input';
import Button from '@/core/design-system/Button';
import useSetupAccount from '../hooks/useSetupAccount.hook';

type SetupAccountProps = {
  onSuccess: (params: SetupAccountRouteParams) => void;
  code: string;
};

const SetupAccountForm = ({ onSuccess, code }: SetupAccountProps) => {
  const setupAccount = useSetupAccount({
    mutationConfig: {
      onSuccess: ({ data }: { data: UserDto }) => {
        onSuccess({ user: data });
      },
    },
  });

  return (
    <div>
      <Form
        onSubmit={(values) => {
          setupAccount.mutate({
            ...values,
            code,
          });
        }}
        schema={setupAccountInputSchema}
      >
        {({ register, formState }) => (
          <>
            <Input label="Full Name" error={formState.errors['name']} registration={register('name')} />
            <Input
              type="password"
              label="Password"
              error={formState.errors['password']}
              registration={register('password')}
            />
            <div>
              <Button>Setup Account</Button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};

export default SetupAccountForm;
