import { useMutation } from '@tanstack/react-query';
import { MutationConfig } from '@/core/lib/query-client';
import { modifyLinkInBio } from '../api/modify-link-in-bio.api';

type UseModifyLinkInBioOptions = {
  mutationConfig?: MutationConfig<typeof modifyLinkInBio>;
};

const useModifyLinkInBio = ({ mutationConfig }: UseModifyLinkInBioOptions = {}) => {
  const { onSuccess, ...restConfig } = mutationConfig || {};

  return useMutation({
    onSuccess,
    ...restConfig,
    mutationFn: modifyLinkInBio,
  });
};

export default useModifyLinkInBio;
