export const LayoutWidths: Record<string, string> = {
  icon: 'w-12 sm:w-16',
  line: 'w-full',
  small: 'w-full',
  default: 'w-full',
};

export const LayoutHeights: Record<string, string> = {
  icon: 'h-12 sm:h-16',
  line: 'h-auto',
  small: '',
  default: '',
};
