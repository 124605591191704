import { z } from 'zod';

export const resetPasswordInputSchema = z
  .object({
    new_password: z
      .string()
      .min(1, { message: 'Password is required!' })
      .min(8, { message: 'Passowrd should be at least 8 characters long!' }),
    confirm_password: z
      .string()
      .min(1, { message: 'Password is required!' })
      .min(8, { message: 'Passowrd should be at least 8 characters long!' }),
  })
  .superRefine(({ confirm_password, new_password }, ctx) => {
    if (confirm_password !== new_password) {
      ctx.addIssue({
        code: 'custom',
        message: 'The passwords do not match',
        path: ['confirm_password'],
      });
    }
  });
