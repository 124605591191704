import AuthLayout from '@/core/layouts/auth-layout';
import ConfirmationEmailInstructions from '@/modules/auth/components/ConfirmationEmailInstructions';
import { useLocation } from 'react-router-dom';

const ConfirmationEmailInstructionsRoute = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email')!;

  return (
    <AuthLayout title="Confirmation Email Instructions">
      <ConfirmationEmailInstructions email={email} />
    </AuthLayout>
  );
};

export default ConfirmationEmailInstructionsRoute;
