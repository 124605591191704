import ShareablePreviewLayout from '@/core/layouts/shareable-preview-layout';
import { ShareablePreview } from '@/modules/link-in-bio/components/ShareablePreview';

const ShareablePreviewRoute = () => {
  return (
    <ShareablePreviewLayout>
      <ShareablePreview />
    </ShareablePreviewLayout>
  );
};

export default ShareablePreviewRoute;
