import LinkPreviewFavicon from './components/Favicon';
import LinkPreviewTitle from './components/Title';
import LinkPreviewCTAButton from './components/CTAButton';
import LinkPreviewThumbnail from './components/Thumbnail';
import { useLinkPreview } from '@/modules/playground/hooks/useLinkPreview.hook';

const DefaultLayout = () => {
  const { link } = useLinkPreview();

  return (
    <div className="w-full p-4">
      <div className="grid grid-cols-2 gap-4 w-full">
        <div className="text-left">
          <LinkPreviewFavicon link={link} />
          <LinkPreviewTitle />
          <div className="hidden sm:block">
            <LinkPreviewCTAButton />
          </div>
        </div>
        <div>
          <LinkPreviewThumbnail link={link} />
        </div>
      </div>

      <div className="block sm:hidden">
        <LinkPreviewCTAButton />
      </div>
    </div>
  );
};

export default DefaultLayout;
