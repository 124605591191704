export type FieldMessageProps = {
  message?: string | null;
};

const FieldMessage = ({ message }: FieldMessageProps) => {
  if (!message) return null;

  return (
    <div role="alert" aria-label={message} className="text-sm font-semibold">
      {message}
    </div>
  );
};

export default FieldMessage;
