export const IconLayoutIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
    <path
      d="M6.5 1.25H1.5C1.36193 1.25 1.25 1.36193 1.25 1.5V6.5C1.25 6.63807 1.36193 6.75 1.5 6.75H6.5C6.63807 6.75 6.75 6.63807 6.75 6.5V1.5C6.75 1.36193 6.63807 1.25 6.5 1.25Z"
      stroke="#F3F4F6"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
