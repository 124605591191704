import * as React from 'react';
import { cn } from '@/core/utils/cn';

type CardProps = {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
};

const Card = ({ children, className, onClick }: CardProps) => {
  return (
    <div
      className={cn(
        'cursor-pointer rounded-xl sm:rounded-2xl border-2 border-secondary w-full h-[200px] hover:bg-dark',
        className,
      )}
      onClick={onClick}
    >
      <div className="rounded-[14px] border-8 border-dark w-full h-full flex flex-col justify-center items-center">
        {children}
      </div>
    </div>
  );
};

export default Card;
