type EnterKBDProps = {
  onClick: () => void;
};

export const EnterKBD = ({ onClick }: EnterKBDProps) => {
  return (
    <div className="absolute inset-y-0 right-0 flex py-1 pr-1 cursor-pointer">
      <kbd
        className="inline-flex items-center rounded-[5px] bg-secondary p-3 text-sm text-light font-bold hover:bg-primary hover:text-lighter transition-all duration-200 ease-in-out"
        onClick={onClick}
      >
        ↵ Enter
      </kbd>
    </div>
  );
};
