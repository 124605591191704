import { useMutation } from '@tanstack/react-query';
import { MutationConfig } from '@/core/lib/query-client';
import { resetPassword } from '../api/reset-password.api';

type UseResetPasswordOptions = {
  mutationConfig?: MutationConfig<typeof resetPassword>;
};

const useResetPassword = ({ mutationConfig }: UseResetPasswordOptions = {}) => {
  const { onSuccess, ...restConfig } = mutationConfig || {};

  return useMutation({
    onSuccess,
    ...restConfig,
    mutationFn: resetPassword,
  });
};

export default useResetPassword;
