import * as React from 'react';
import { type UseFormRegisterReturn } from 'react-hook-form';
import { cn } from '@/core/utils/cn';
import FieldWrapper, { FieldWrapperPassThroughProps } from '../base/FieldWrapper';

export type CheckboxProps = React.InputHTMLAttributes<HTMLInputElement> &
  FieldWrapperPassThroughProps & {
    registration: Partial<UseFormRegisterReturn>;
    className?: string;
  };

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ className, type, label, error, registration, ...props }, ref) => {
    return (
      <FieldWrapper error={error}>
        <label className="flex items-center cursor-pointer">
          <input
            type="checkbox"
            className={cn('h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600', className)}
            ref={ref}
            {...registration}
            {...props}
          />
          <span className="ml-3 block text-sm text-gray-900">{label}</span>
        </label>
      </FieldWrapper>
    );
  },
);

export default Checkbox;
