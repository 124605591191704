import { loginInputSchema } from '../validators/login.validator';
import { UserDto } from '@link-in-bio/domain';
import { LoginRouteParams } from '@/app/router/auth/Login.route';
import Form from '@/core/components/form/Form';
import Input from '@/core/components/form/fields/Input';
import Checkbox from '@/core/components/form/fields/Checkbox';
import Button from '@/core/design-system/Button';
import useLogin from '../hooks/useLogin.hook';
import Link from '@/core/design-system/Link';

type LoginFormProps = {
  onSuccess: (params: LoginRouteParams) => void;
};

const LoginForm = ({ onSuccess }: LoginFormProps) => {
  const login = useLogin({
    mutationConfig: {
      onSuccess: ({ data }: { data: UserDto }) => {
        onSuccess({ user: data });
      },
    },
  });

  return (
    <div>
      <Form
        onSubmit={(values) => {
          login.mutate(values);
        }}
        schema={loginInputSchema}
      >
        {({ register, formState }) => (
          <>
            <Input
              type="email"
              label="Email Address"
              error={formState.errors['email']}
              registration={register('email')}
            />
            <Input
              type="password"
              label="Password"
              error={formState.errors['password']}
              registration={register('password')}
            />

            <div className="flex items-center justify-end">
              <Link to="/auth/forgot-password" className="text-sm font-medium text-primary hover:text-primary-dark">
                Forgot Password?
              </Link>
            </div>

            <Checkbox
              label="Remember Me"
              error={formState.errors['remember_me']}
              registration={register('remember_me')}
            />

            <div>
              <Button>Log in</Button>
            </div>
          </>
        )}
      </Form>
      <div className="mt-2 flex items-center justify-center">
        <Link to="/auth/register" className="text-sm font-medium text-primary hover:text-primary-dark">
          Register
        </Link>
      </div>
    </div>
  );
};

export default LoginForm;
