import { motion } from 'framer-motion';
import usePlayground from '../hooks/usePlayground.hook';
import { PlaygroundMode } from '../enums/mode.enum';
import { isPreviewRoute } from '@/core/utils/is-preview-route';

type ModeRenderizationProps = {
  children: React.ReactNode;
  variants?: any;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
};

const MotionDiv = motion.div;

const ModeRenderization = ({ children, variants, className, style, onClick }: ModeRenderizationProps) => {
  const { state } = usePlayground();

  const getInitialState = () => {
    if (isPreviewRoute()) {
      return 'Preview';
    }

    if (state.mode === PlaygroundMode.EDITOR) {
      return 'Preview';
    }

    return 'Editor';
  };

  const getAnimateState = () => {
    if (isPreviewRoute()) {
      return 'Preview';
    }

    if (state.mode === PlaygroundMode.EDITOR) {
      return 'Editor';
    }

    return 'Preview';
  };

  return (
    <MotionDiv
      key={state.mode}
      initial={getInitialState()}
      animate={getAnimateState()}
      variants={variants}
      className={className}
      style={style}
      onClick={onClick}
      transition={{
        duration: 0.4,
        ease: 'easeInOut',
      }}
    >
      {children}
    </MotionDiv>
  );
};

export default ModeRenderization;
