import LinkPreviewFavicon from './components/Favicon';
import { useLinkPreview } from '@/modules/playground/hooks/useLinkPreview.hook';

const IconLayout = () => {
  const { link } = useLinkPreview();

  return (
    <div className="w-full flex justify-center items-center">
      <LinkPreviewFavicon link={link} />
    </div>
  );
};

export default IconLayout;
