import Form from '@/core/components/form/Form';
import usePlayground from '../../hooks/usePlayground.hook';
import Profile from './profile';
import Links from './links';

const PlaygroundForm = () => {
  const { state } = usePlayground();

  return (
    <Form
      onSubmit={() => {}}
      options={{
        values: state.payload,
      }}
    >
      {() => (
        <div className="px-[25px] md:px-[75px] py-[45px] flex flex-col items-center justify-center w-full">
          <Profile />
          <Links />
        </div>
      )}
    </Form>
  );
};

export default PlaygroundForm;
