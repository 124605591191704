export const VisibilityVariants = {
  Editor: {
    pointerEvents: 'auto' as 'auto',
    visibility: 'visible' as 'visible',
    opacity: 1,
  },
  Preview: {
    pointerEvents: 'none' as 'none',
    visibility: 'hidden' as 'hidden',
    opacity: 0,
  },
};

export const VisibilityWithHeightVariants = {
  Editor: {
    pointerEvents: 'auto' as 'auto',
    visibility: 'visible' as 'visible',
    opacity: 1,
    height: 'auto',
    marginBottom: 20,
    marginLeft: 4,
    marginRight: 4,
  },
  Preview: {
    pointerEvents: 'none' as 'none',
    visibility: 'hidden' as 'hidden',
    opacity: 0,
    height: 0,
    marginBottom: 0,
    marginLeft: 4,
    marginRight: 4,
  },
};
