import { api } from '@/core/lib/api-client';
import { ForgotPasswordDto, ResponseType } from '@link-in-bio/domain';

export const forgotPassword = ({ email, captcha_token }: ForgotPasswordDto): Promise<ResponseType<void>> => {
  return api.post(
    `/auth/forgot-password`,
    { email },
    {
      headers: {
        'x-recaptcha-token': captcha_token,
      },
    },
  );
};
