import AuthLayout from '@/core/layouts/auth-layout';
import RegistrationForm from '@/modules/auth/components/RegisterForm';
import { useNavigate } from 'react-router-dom';

export type RegisterRouteParams = {
  email: string;
};

const RegisterRoute = () => {
  const navigate = useNavigate();

  const onSuccessHandler = (params: RegisterRouteParams) => {
    navigate(`/auth/confirmation-email-instructions?email=${params.email}`);
  };

  return (
    <AuthLayout title="Register">
      <RegistrationForm onSuccess={onSuccessHandler} />
    </AuthLayout>
  );
};

export default RegisterRoute;
