import { useMutation } from '@tanstack/react-query';
import { MutationConfig } from '@/core/lib/query-client';
import { uploadProfileImage } from '../api/upload-profile-image.api';

type UseUploadProfileImageOptions = {
  mutationConfig?: MutationConfig<typeof uploadProfileImage>;
};

const useUploadProfileImage = ({ mutationConfig }: UseUploadProfileImageOptions = {}) => {
  const { onSuccess, ...restConfig } = mutationConfig || {};

  return useMutation({
    onSuccess,
    ...restConfig,
    mutationFn: uploadProfileImage,
  });
};

export default useUploadProfileImage;
