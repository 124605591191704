import AuthLayout from '@/core/layouts/auth-layout';
import ForgotPasswordForm from '@/modules/auth/components/ForgotPasswordForm';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

const ForgotPasswordRoute = () => {
  const navigate = useNavigate();

  const onSuccessHandler = () => {
    navigate('/auth/login');
    toast.success('Email has been sent successfully!');
  };

  return (
    <AuthLayout title="Forgot Password">
      <ForgotPasswordForm onSuccess={onSuccessHandler} />
    </AuthLayout>
  );
};

export default ForgotPasswordRoute;
