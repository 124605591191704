import { useFormContext } from 'react-hook-form';
import { PlaygroundActionType } from '@/modules/playground/types/playground-action.type';
import usePlayground from '@/modules/playground/hooks/usePlayground.hook';
import { PlaygroundMode } from '@/modules/playground/enums/mode.enum';
import TwitterText from 'twitter-text';
import Button from '@/core/design-system/Button';
import { cn } from '@/core/utils/cn';
import { useRef, useState } from 'react';
import Input from '@/core/components/form/fields/Input';
import { motion } from 'framer-motion';
import { PointerEventVariants } from '@/core/constants/mode-variants/event.variants';
import { EnterKBD } from '@/core/components/form/fields/EnterKBD';
import toast from 'react-hot-toast';

const MotionButtonInput = motion.create(Input);

const ProfileCTAButton = () => {
  const { register } = useFormContext();
  const { state, dispatch, dispatchWithDebounce } = usePlayground();

  const [ctaInput, setCTAInput] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const toggleCTAInput = () => {
    setCTAInput((prev) => !prev);
  };

  const dispatchAddLinkHandler = () => {
    const link = inputRef.current?.value;
    if (link && !TwitterText.isValidUrl(link, true, true)) {
      toast.error('Please enter a valid link');
      return;
    }

    dispatch({
      type: PlaygroundActionType.SET_PAYLOAD,
      payload: {
        cta_button: {
          url: link,
        },
      },
    });
    toggleCTAInput();
  };

  const addLinkKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      dispatchAddLinkHandler();
    }
  };

  const getContainerClassname = () => {
    if (!Boolean(hasButton) && state.mode === PlaygroundMode.PREVIEW)
      return 'invisible opacity-0 pointer-events-none h-0 my-0';
    if (Boolean(hasButton) && state.mode === PlaygroundMode.PREVIEW) return 'mb-0 h-auto';
  };

  const hasButton = state.payload.cta_button?.label;
  const hasButtonUrl = state.payload.cta_button?.url;

  return (
    <div className={cn('mt-4 h-[55px] w-[80%] mx-auto', getContainerClassname())}>
      {ctaInput ? (
        <div className="relative flex items-center w-full h-auto">
          <input
            ref={inputRef}
            type="text"
            className="block w-full h-10 rounded-lg border border-solid border-input-focus py-[14px] px-2 text-white bg-main focus:outline-none"
            defaultValue="https://"
            autoFocus
            onKeyDown={addLinkKeyDownHandler}
          />
          <EnterKBD onClick={dispatchAddLinkHandler} />
        </div>
      ) : (
        <Button
          type="button"
          className={cn(
            'bg-transparent hover:text-lighter py-0 w-[150px] hover:border-transparent mx-auto cursor-pointer',
            hasButton && 'bg-primary',
          )}
          onClick={() => {
            if (!hasButtonUrl) {
              toggleCTAInput();
            }

            if (hasButtonUrl && state.mode === PlaygroundMode.PREVIEW) {
              window.open(hasButtonUrl, '_blank');
            }
          }}
        >
          <MotionButtonInput
            key={state.mode}
            initial={state.mode === PlaygroundMode.EDITOR ? 'Editor' : 'Preview'}
            animate={state.mode === PlaygroundMode.EDITOR ? 'Preview' : 'Editor'}
            variants={PointerEventVariants}
            placeholder="Add Button"
            registration={register('cta_button.label')}
            onChange={(e) => {
              dispatchWithDebounce({
                type: PlaygroundActionType.SET_PAYLOAD,
                payload: {
                  cta_button: {
                    label: e.target.value,
                  },
                },
              });

              if (!e.target.value) {
                dispatchWithDebounce({
                  type: PlaygroundActionType.SET_PAYLOAD,
                  payload: {
                    cta_button: {
                      label: '',
                      url: '',
                    },
                  },
                });
              }
            }}
            className={cn(
              'text-center cursor-pointer outline-none font-bold font-sm py-2 border-secondary border border-solid px-3 w-[150px] text-surface hover:bg-transparent hover:border-transparent hover:text-lighter focus:text-lighter focus:bg-primary focus:border-transparent',
              hasButton && 'text-lighter border-transparent',
            )}
            autoFocus={Boolean(hasButtonUrl) === true && Boolean(hasButton) === false}
          />
        </Button>
      )}
    </div>
  );
};

export default ProfileCTAButton;
