import { useNoltWidget } from '@/core/hooks/useNoltWidget.hook';
import AuthLayout from '@/core/layouts/auth-layout';
import LoginForm from '@/modules/auth/components/LoginForm';
import useAuth from '@/modules/auth/hooks/useAuth.hook';
import { AuthActionType } from '@/modules/auth/types/auth-action.type';
import { UserDto } from '@link-in-bio/domain';

export type LoginRouteParams = {
  user: UserDto;
};

const LoginRoute = () => {
  const { dispatch } = useAuth();
  const { noltIdentify } = useNoltWidget();

  const onSuccessHandler = (params: LoginRouteParams) => {
    dispatch({ type: AuthActionType.SET_USER, payload: params.user });
    noltIdentify();
  };

  return (
    <AuthLayout title="Log in">
      <LoginForm onSuccess={onSuccessHandler} />
    </AuthLayout>
  );
};

export default LoginRoute;
