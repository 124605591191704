import Glow from '@/core/design-system/Glow';
import * as React from 'react';

type ShareablePreviewLayoutProps = {
  children: React.ReactNode;
};

const ShareablePreviewLayout = ({ children }: ShareablePreviewLayoutProps) => {
  return (
    <div className="min-h-screen bg-main flex flex-col relative">
      <Glow />
      <div className="flex items-center justify-center grow pb-10 z-10">
        <div className="max-w-playground w-full">{children}</div>
      </div>
    </div>
  );
};

export default ShareablePreviewLayout;
