import { useState } from 'react';
import ShareDialog from './ShareDialog';
import ShareButton from './ShareButton';

const Share = () => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const toggleDialog = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  return (
    <>
      <ShareButton toggleDialog={toggleDialog} />
      <ShareDialog isOpen={isDialogOpen} toggleDialog={toggleDialog} />
    </>
  );
};

export default Share;
