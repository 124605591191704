import { api } from '@/core/lib/api-client';
import { RegisterDto, ResponseType, UserDto } from '@link-in-bio/domain';

export const register = ({ captcha_token, ...body }: RegisterDto): Promise<ResponseType<UserDto>> => {
  return api.post('/auth/register', body, {
    headers: {
      'x-recaptcha-token': captcha_token,
    },
  });
};
