import { useMutation } from '@tanstack/react-query';
import { MutationConfig } from '@/core/lib/query-client';
import { setupAccount } from '../api/setup-account.api';

type UseSetupAccountOptions = {
  mutationConfig?: MutationConfig<typeof setupAccount>;
};

const useSetupAccount = ({ mutationConfig }: UseSetupAccountOptions = {}) => {
  const { onSuccess, ...restConfig } = mutationConfig || {};

  return useMutation({
    onSuccess,
    ...restConfig,
    mutationFn: setupAccount,
  });
};

export default useSetupAccount;
