import { useLinkPreview } from '@/modules/playground/hooks/useLinkPreview.hook';
import LinkPreviewFavicon from './components/Favicon';
import LinkPreviewTitle from './components/Title';
import { ExternalLinkIcon } from '@/assets/icons/external-link.icon';

const LineLayout = () => {
  const { link } = useLinkPreview();

  return (
    <div className="w-full px-4 flex justify-between items-center">
      <div className="flex justify-start items-center w-full">
        <LinkPreviewFavicon link={link} />
        <LinkPreviewTitle className="ml-4" rows={1} maxHeight="max-h-[2.4rem]" />
      </div>

      <div
        onClick={() => {
          window.open(link.url, '_blank');
        }}
      >
        <ExternalLinkIcon />
      </div>
    </div>
  );
};

export default LineLayout;
