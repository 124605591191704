import { useState, useEffect } from 'react';

const useScript = (url: string) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    let script: HTMLScriptElement | null = null;

    const onLoad = () => {
      setIsLoaded(true);
      script = null;
    };

    const onError = (error: any) => {
      setError(error);
    };

    if (!isLoaded) {
      script = document.createElement('script');
      script.src = url;
      script.async = true;
      script.onload = onLoad;
      script.onerror = onError;
      document.body.appendChild(script);
    }

    return () => {
      if (script) {
        script.onload = null;
        script.onerror = null;
        document.body.removeChild(script);
      }
    };
  }, [url, isLoaded]);

  return { isLoaded, error };
};

export default useScript;
