import { registerInputSchema } from '../validators/register.validator';
import { RegisterDto, UserDto } from '@link-in-bio/domain';
import ReCAPTCHA from 'react-google-recaptcha';
import { useRef } from 'react';
import { RegisterRouteParams } from '@/app/router/auth/Register.route';
import { PlaygroundActionType } from '@/modules/playground/types/playground-action.type';
import Form from '@/core/components/form/Form';
import Input from '@/core/components/form/fields/Input';
import Button from '@/core/design-system/Button';
import Recaptcha from '@/core/components/recaptcha';
import useRegister from '../hooks/useRegister.hook';
import usePlayground from '@/modules/playground/hooks/usePlayground.hook';
import Link from '@/core/design-system/Link';

type RegistrationFormProps = {
  onSuccess: (params: RegisterRouteParams) => void;
};

const RegistrationForm = ({ onSuccess }: RegistrationFormProps) => {
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);
  const { state, dispatch } = usePlayground();

  const register = useRegister({
    mutationConfig: {
      onSuccess: ({ data }: { data: UserDto }) => {
        onSuccess({ email: data.email });
      },
    },
  });

  const onSubmitHandler = async (values: RegisterDto) => {
    const captcha_token = await recaptchaRef.current?.executeAsync();
    const { username, ...restValues } = values;
    const { username: payloadUsername, ...restPayload } = state.payload;

    register.mutate({
      ...restValues,
      ...restPayload,
      username: payloadUsername || username,
      captcha_token: captcha_token || '',
    });

    recaptchaRef.current?.reset();
    dispatch({ type: PlaygroundActionType.CLEAR_PAYLOAD });
  };

  return (
    <div>
      <Form
        onSubmit={onSubmitHandler}
        schema={registerInputSchema}
        options={{
          defaultValues: {
            username: state.payload.username,
          },
        }}
      >
        {({ register, formState }) => (
          <>
            <Input label="Username" error={formState.errors['username']} registration={register('username')} />
            <Input
              type="email"
              label="Email Address"
              error={formState.errors['email']}
              registration={register('email')}
            />

            <div>
              <Button>Register</Button>
            </div>

            <Recaptcha ref={recaptchaRef} />
          </>
        )}
      </Form>
      <div className="mt-2 flex items-center justify-center">
        <Link to="/auth/login" className="text-sm font-medium text-primary hover:text-primary-dark">
          Login
        </Link>
      </div>
    </div>
  );
};

export default RegistrationForm;
