import { useMutation } from '@tanstack/react-query';
import { MutationConfig } from '@/core/lib/query-client';
import { resendEmailConfirmation } from '../api/resend-email-confirmation.api';

type UseResendEmailConfirmationOptions = {
  mutationConfig?: MutationConfig<typeof resendEmailConfirmation>;
};

const useResendEmailConfirmation = ({ mutationConfig }: UseResendEmailConfirmationOptions = {}) => {
  const { onSuccess, onError, ...restConfig } = mutationConfig || {};

  return useMutation({
    onSuccess,
    onError,
    ...restConfig,
    mutationFn: resendEmailConfirmation,
  });
};

export default useResendEmailConfirmation;
