import LinkPreviewFavicon from './components/Favicon';
import LinkPreviewTitle from './components/Title';
import LinkPreviewCTAButton from './components/CTAButton';
import { useLinkPreview } from '@/modules/playground/hooks/useLinkPreview.hook';

const SmallLayout = () => {
  const { link } = useLinkPreview();

  return (
    <div className="w-full p-4">
      <div className="text-left">
        <LinkPreviewFavicon link={link} />
        <LinkPreviewTitle />
        <LinkPreviewCTAButton />
      </div>
    </div>
  );
};

export default SmallLayout;
