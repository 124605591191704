import { DefaultLayoutIcon } from '@/assets/icons/default-layout.icon';
import { LineLayoutIcon } from '@/assets/icons/line-layout.icon';
import { IconLayoutIcon } from '@/assets/icons/icon-layout.icon';
import * as React from 'react';
import { SmallLayoutIcon } from '@/assets/icons/small-layout.icon';
import usePlayground from '@/modules/playground/hooks/usePlayground.hook';
import { PlaygroundActionType } from '@/modules/playground/types/playground-action.type';
import { ENUM_LINK_LAYOUT } from '@/modules/playground/enums/link.enum';

export const LayoutButtonItem = ({ children, onClick }: { children: React.ReactNode; onClick: () => void }) => (
  <div
    className="p-1 hover:bg-surface-dark rounded transition-all duration-200 ease-in-out flex justify-center items-center w-6 h-6"
    onClick={onClick}
  >
    {children}
  </div>
);

const LayoutButtons = ({ linkId }: { linkId: string }) => {
  const { dispatch } = usePlayground();

  const handleLayoutChange = (layout: string) => {
    dispatch({
      type: PlaygroundActionType.SET_LINK_PAYLOAD,
      payload: {
        [`link.${linkId}.layout`]: layout,
        linkId,
      },
    });
  };

  return (
    <div className="bg-surface-gradient shadow-surface-shadow z-10 absolute -bottom-5 left-1/2 transform -translate-x-1/2 md:flex gap-[10px] rounded-[10px] justify-center items-center p-2 hidden md:opacity-0 invisible pointer-events-none group-hover/card:flex md:group-hover/card:opacity-100 group-hover/card:visible group-hover/card:pointer-events-auto transition-all duration-200 ease-in-out">
      <LayoutButtonItem onClick={() => handleLayoutChange(ENUM_LINK_LAYOUT.ICON)}>
        <IconLayoutIcon />
      </LayoutButtonItem>
      <LayoutButtonItem onClick={() => handleLayoutChange(ENUM_LINK_LAYOUT.LINE)}>
        <LineLayoutIcon />
      </LayoutButtonItem>
      <LayoutButtonItem onClick={() => handleLayoutChange(ENUM_LINK_LAYOUT.SMALL)}>
        <SmallLayoutIcon />
      </LayoutButtonItem>
      <LayoutButtonItem onClick={() => handleLayoutChange(ENUM_LINK_LAYOUT.DEFAULT)}>
        <DefaultLayoutIcon />
      </LayoutButtonItem>
    </div>
  );
};

export default LayoutButtons;
