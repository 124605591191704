import { useImperativeHandle, forwardRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import useRecaptcha from '@/core/hooks/useRecaptcha.hook';

const Recaptcha = forwardRef((_props, ref) => {
  const { recaptchaRef, handleRecaptcha } = useRecaptcha();

  useImperativeHandle(ref, () => recaptchaRef.current);

  if (process.env.NODE_ENV === 'development') return null;

  return (
    <ReCAPTCHA
      ref={recaptchaRef}
      sitekey={process.env.RECAPTCHA_SITE_KEY as string}
      onChange={handleRecaptcha}
      size="invisible"
    />
  );
});

export default Recaptcha;
