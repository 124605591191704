import { cn } from '@/core/utils/cn';
import * as React from 'react';
import usePlayground from '../hooks/usePlayground.hook';
import { PlaygroundMode } from '../enums/mode.enum';

type BrowserFrameProps = {
  children: React.ReactNode;
};

export const BrowserTaskbar = () => {
  return (
    <div className="flex gap-2 bg-browser-taskbar px-3 py-2 rounded-tl-[4px] rounded-tr-[4px] border-b border-b-1 border-surface">
      <div className="w-3 h-3 rounded-full bg-[#E96E4C]" />
      <div className="w-3 h-3 rounded-full bg-[#E6A935]" />
      <div className="w-3 h-3 rounded-full bg-[#85C33D]" />
    </div>
  );
};

const BrowserFrame = ({ children }: BrowserFrameProps) => {
  const { state } = usePlayground();

  return (
    <div
      className={cn(
        'rounded-xl border-8 border-surface',
        state.mode === PlaygroundMode.EDITOR ? 'min-h-[calc(100vh-160px)]' : 'min-h-fit',
      )}
    >
      <BrowserTaskbar />
      {children}
    </div>
  );
};

export default BrowserFrame;
