export const ShareIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M5.50007 8.43224L11.1188 13.376C11.1838 13.4335 11.2625 13.4731 11.3474 13.4909C11.4323 13.5088 11.5203 13.5042 11.6029 13.4777C11.6855 13.4513 11.7598 13.4038 11.8184 13.3399C11.8771 13.276 11.9182 13.198 11.9376 13.1135L14.2876 2.85099C14.3073 2.76261 14.3026 2.67054 14.2741 2.5846C14.2455 2.49867 14.1942 2.4221 14.1255 2.36306C14.0569 2.30402 13.9735 2.26474 13.8842 2.24941C13.795 2.23407 13.7032 2.24326 13.6188 2.27599L2.08132 6.80724C1.61882 6.98849 1.68132 7.66349 2.16882 7.76349L5.50007 8.43224Z"
      stroke="#F3F4F6"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.5 8.43281L14.0063 2.28906"
      stroke="#F3F4F6"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.30625 10.8984L6.35625 12.8484C6.28668 12.9191 6.19768 12.9674 6.10056 12.9874C6.00344 13.0073 5.90259 12.9979 5.81081 12.9604C5.71904 12.9229 5.64049 12.859 5.58515 12.7767C5.5298 12.6944 5.50017 12.5976 5.5 12.4984V8.42969"
      stroke="#F3F4F6"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
