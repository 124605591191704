import AuthLayout from '@/core/layouts/auth-layout';
import ResetPasswordForm from '@/modules/auth/components/ResetPasswordForm';
import useVerifyTwoFactorCode from '@/modules/auth/hooks/useVerifyTwoFactorCode.hook';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const ResetPasswordRoute = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code')!;

  const onSuccessHandler = () => {
    navigate('/auth/login');
  };

  const onErrorHandler = () => {
    navigate('/auth/forgot-password');
  };

  const verifyTwoFactorCode = useVerifyTwoFactorCode({
    mutationConfig: {
      onError: onErrorHandler,
    },
  });

  useEffect(() => {
    verifyTwoFactorCode.mutate({
      code,
    });
  }, []);

  return (
    <AuthLayout title="Reset Password">
      <ResetPasswordForm onSuccess={onSuccessHandler} code={code} />
    </AuthLayout>
  );
};

export default ResetPasswordRoute;
