import { useNoltWidget } from '../hooks/useNoltWidget.hook';
import toast from 'react-hot-toast';

type NoltWidgetProps = {
  children: React.ReactNode;
};

const NoltWidget = ({ children }: NoltWidgetProps) => {
  const { isLoaded, error, noltModal } = useNoltWidget();

  if (error) {
    return toast.error('Failed to load Nolt widget');
  }

  if (!isLoaded) {
    return children;
  }

  noltModal();

  return children;
};

export default NoltWidget;
