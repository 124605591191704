import { useEffect } from 'react';

const usePageUnload = (
  shouldPrompt: boolean = false,
  customMessage: string = 'You have unsaved changes. Are you sure you want to leave?',
): void => {
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (shouldPrompt) {
        event.preventDefault();
        return customMessage;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [shouldPrompt, customMessage]);
};

export default usePageUnload;
