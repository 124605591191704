import * as React from 'react';
import Glow from '@/core/design-system/Glow';
import Header from './components/Header';
import usePlayground from '@/modules/playground/hooks/usePlayground.hook';
import usePageUnload from '@/core/hooks/usePageUnload.hook';

type PlaygroundLayoutProps = {
  children: React.ReactNode;
};

const PlaygroundLayout = ({ children }: PlaygroundLayoutProps) => {
  const { state } = usePlayground();
  usePageUnload(state.actions.length > 0);

  return (
    <div className="min-h-screen bg-main flex flex-col relative">
      <Glow />
      <Header />
      <div className="flex items-center justify-center grow pb-10 z-10">
        <div className="max-w-playground w-full">{children}</div>
      </div>
    </div>
  );
};

export default PlaygroundLayout;
