import { useMutation } from '@tanstack/react-query';
import { MutationConfig } from '@/core/lib/query-client';
import { logout } from '../api/logout.api';

type UseLogoutOptions = {
  mutationConfig?: MutationConfig<typeof logout>;
};

const useLogout = ({ mutationConfig }: UseLogoutOptions = {}) => {
  const { onSuccess, ...restConfig } = mutationConfig || {};

  return useMutation({
    onSuccess,
    ...restConfig,
    mutationFn: logout,
  });
};

export default useLogout;
