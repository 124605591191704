import { api } from '@/core/lib/api-client';
import { ResponseType, UploadProfileImageDto } from '@link-in-bio/domain';

export const uploadProfileImage = ({ linkInBioId, file }: UploadProfileImageDto): Promise<ResponseType<void>> => {
  const formData = new FormData();
  formData.append('file', file);

  return api.post(`/link-in-bio/upload-profile-image/${linkInBioId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
