import { useContext } from 'react';
import { LinkPreviewContext } from '../context/link-preview.context';

export function useLinkPreview() {
  const context = useContext(LinkPreviewContext);

  if (!context) {
    throw new Error('useLinkPreview must be used within a LinkPreviewProvider');
  }

  return context;
}
