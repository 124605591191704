export const SmallLayoutIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="16" viewBox="0 0 19 16" fill="none">
    <path
      d="M17.2273 4H1.77273C1.34596 4 1 4.51167 1 5.14286V10.8571C1 11.4883 1.34596 12 1.77273 12H17.2273C17.654 12 18 11.4883 18 10.8571V5.14286C18 4.51167 17.654 4 17.2273 4Z"
      stroke="#F3F4F6"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
