import { useMutation } from '@tanstack/react-query';
import { login } from '../api/login.api';
import { MutationConfig } from '@/core/lib/query-client';

type UseLoginOptions = {
  mutationConfig?: MutationConfig<typeof login>;
};

const useLogin = ({ mutationConfig }: UseLoginOptions = {}) => {
  const { onSuccess, ...restConfig } = mutationConfig || {};

  return useMutation({
    onSuccess,
    ...restConfig,
    mutationFn: login,
  });
};

export default useLogin;
