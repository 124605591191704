import { createContext, ReactNode } from 'react';
import usePlayground from '../hooks/usePlayground.hook';
import { ISetLinkPayloadAction, PlaygroundActionType } from '../types/playground-action.type';
import { LinkDto } from '@link-in-bio/domain';

type LinkPreviewProviderProps = {
  children: ReactNode;
  link: LinkDto | any;
};

export const LinkPreviewContext = createContext<
  | {
      link: LinkDto | any;
      getLinkIndex: Function;
      handleInputChange: Function;
    }
  | undefined
>(undefined);

function LinkPreviewProvider({ children, link }: LinkPreviewProviderProps) {
  const { state, dispatchWithDebounce } = usePlayground();

  const handleInputChange =
    (actionType: PlaygroundActionType.SET_LINK_PAYLOAD) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value, name } = e.target;
      const action: ISetLinkPayloadAction = {
        type: actionType,
        payload: {
          [name]: value,
          linkId: link._id || link.client_id,
        },
      };

      dispatchWithDebounce(action);
    };

  const getLinkIndex = () => {
    return (
      state.payload.links?.findIndex((l) => {
        if (l._id) return l._id === link._id;
        return l.client_id === link.client_id;
      }) ?? 0
    );
  };

  const value = {
    link,
    getLinkIndex,
    handleInputChange,
  };

  return <LinkPreviewContext.Provider value={value}>{children}</LinkPreviewContext.Provider>;
}

export default LinkPreviewProvider;
