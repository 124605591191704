import { useMutation } from '@tanstack/react-query';
import { MutationConfig } from '@/core/lib/query-client';
import { getLinkie } from '../api/get-linkie.api';

type UseGetLinkieOptions = {
  mutationConfig?: MutationConfig<typeof getLinkie>;
};

const useGetLinkie = ({ mutationConfig }: UseGetLinkieOptions = {}) => {
  const { onSuccess, ...restConfig } = mutationConfig || {};

  return useMutation({
    onSuccess,
    ...restConfig,
    mutationFn: getLinkie,
  });
};

export default useGetLinkie;
