export const LineLayoutIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M13 6H3C2.72386 6 2.5 6.22386 2.5 6.5V9C2.5 9.27614 2.72386 9.5 3 9.5H13C13.2761 9.5 13.5 9.27614 13.5 9V6.5C13.5 6.22386 13.2761 6 13 6Z"
      stroke="#F3F4F6"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
