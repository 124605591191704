import AuthLayout from '@/core/layouts/auth-layout';
import SetupAccountForm from '@/modules/auth/components/SetupAccountForm';
import useAuth from '@/modules/auth/hooks/useAuth.hook';
import useVerifyTwoFactorCode from '@/modules/auth/hooks/useVerifyTwoFactorCode.hook';
import { AuthActionType } from '@/modules/auth/types/auth-action.type';
import { UserDto } from '@link-in-bio/domain';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export type SetupAccountRouteParams = {
  user: UserDto;
};

const SetupAccountRoute = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { dispatch } = useAuth();
  const code = searchParams.get('code')!;

  const onSuccessHandler = (params: SetupAccountRouteParams) => {
    dispatch({ type: AuthActionType.SET_USER, payload: params.user });
    navigate('/playground');
  };

  const onErrorHandler = () => {
    navigate('/auth/resend-email-confirmation');
  };

  const verifyTwoFactorCode = useVerifyTwoFactorCode({
    mutationConfig: {
      onError: onErrorHandler,
    },
  });

  useEffect(() => {
    verifyTwoFactorCode.mutate({
      code,
    });
  }, []);

  return (
    <AuthLayout title="Setup Account">
      <SetupAccountForm onSuccess={onSuccessHandler} code={code} />
    </AuthLayout>
  );
};

export default SetupAccountRoute;
